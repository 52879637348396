<template>
    <k-layout no-header no-footer class="tw-bg-gray-100">
        <main class="tw-px-5 md:tw-px-10 lg:tw-px-14 tw-py-12 md:tw-py-20 lg:tw-py-24">
            <div class="tw-max-w-7xl tw-mx-auto">
                <div class="tw-flex tw-flex-col tw-gap-6 md:tw-gap-10 lg:tw-flex-row">
                    <div class="tw-flex-shrink-0 lg:tw-max-w-xs">
                        <h1 class="tw-text-2xl tw-leading-1.4em md:tw-text-2.5xl md:tw-leading-1.25em lg:tw-text-3.5xl tw-font-bold tw-text-gray-800" v-html="$t('profile.subtitles.finish_signup')" />
                    </div>
                    <div class="tw-bg-white tw-rounded-2xl tw-px-6 tw-py-8 md:tw-px-12 md:tw-py-12 lg:tw-px-14 lg:tw-py-14 tw-w-full">
                        <form @submit.prevent="submit" class="tw-grid tw-gap-6 md:tw-gap-8">
                            <div v-show="showUserNameInputs" class="tw-grid md:tw-grid-cols-2 tw-gap-6 md:tw-gap-8">
                                <k-input v-model="firstName" :title="$t('global.inputs.first_name')" />
                                <k-input v-model="lastName" :title="$t('global.inputs.last_name')" />
                            </div>
                            <k-combobox v-model="profession" :items="allProfessions" :title="$t('profile.inputs.profession_finish_up')" item-value="value" item-text="name" />
                            <k-autocomplete v-model="country" :items="allCountries" :title="$t('profile.inputs.country_finish_up')" item-value="code" item-text="name" />
                            <k-language-switcher :language-code="language" @select="language = $event" />
                            <div>
                                <k-autocomplete-technologies
                                    v-model="technologies"
                                    :items="allTechnologies"
                                    :label="$t('profile.inputs.technologies_finish_up')"
                                    :show-limit="3"
                                />
                                <ka-suggest-technologies
                                    class="tw-mt-1"
                                    :profession="profession.value"
                                    :excludeTechnologies="technologies"
                                    @click="
                                        tech => {
                                            technologies = [...technologies, tech];
                                        }
                                    "
                                    limit="5"
                                />
                            </div>

                            <div>
                                <span class="tw-font-bold tw-text-sm tw-text-gray-800"> {{ $t('profile.job_looking_finish_up') }}</span>
                                <v-switch
                                    v-model="lookingForJobSwitch"
                                    inset
                                    :label="lookingForJobSwitch ? $t('global.yes') : $t('global.no')"
                                    class="tw-m-0 tw-p-0 tw-mt-2"
                                    color="#0ca56d"
                                    hide-details
                                />
                            </div>

                            <template v-if="!lookingForJobSwitch">
                                <div>
                                    <span class="tw-font-bold tw-text-sm tw-text-gray-800">{{ $t('profile.job_timeframe_finish_up') }}</span>
                                    <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-gap-2 tw-mt-2">
                                        <button
                                            type="button"
                                            v-for="(value, key) in lookingForJobOptions"
                                            :key="key"
                                            class=" tw-border-2 tw-border-solid tw-px-4 tw-py-6 tw-text-center tw-outline-none hover:tw-bg-gray-100 hover:tw-border-gray-900 tw-rounded tw-transition-colors tw-duration-150 tw-text-gray-800"
                                            :class="{
                                                'tw-bg-transparent tw-border-gray-100 ': availableFrom !== value,
                                                'tw-bg-gray-100 tw-border-gray-900': availableFrom === value
                                            }"
                                            @click="availableFrom = value"
                                        >
                                            {{ key }}
                                        </button>
                                    </div>
                                </div>
                            </template>

                            <v-expand-transition :class="{ 'tw-mt-': !!errorMessage }">
                                <div v-show="errorMessage" class="form-error-message tw-m-0 tw-p-0">
                                    <p>{{ errorMessage }}</p>
                                </div>
                            </v-expand-transition>

                            <div>
                                <k-button color="primary" type="submit" :loading="formState === 'PENDING'">{{ $t('global.actions.complete') }}</k-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    </k-layout>
</template>

<script>
import dateAdd from "date-fns/add";
import dateFormat from "date-fns/format";
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import KaSuggestTechnologies from "@web/components/ka-suggest-technologies";
import { fetchCountries } from "@web/services/index";
import {DEFAULT_LOCALE, getRouteLocale} from '@web/constants/language'
import KLanguageSwitcher from '@web/components/core/k-language-switcher'

export default {
    components: {
        KLanguageSwitcher,
        KaSuggestTechnologies
    },
    data() {
        return {
            allCountries: [],
            firstName: null,
            lastName: null,
            profession: {
                name: null,
                value: null
            },
            country: "sk",
            language: DEFAULT_LOCALE,
            technologies: [],
            lookingForJobSwitch: true,
            availableFrom: Date.now(),
            errorMessage: null,
        };
    },
    mounted() {
        fetchCountries().then(countries => {
            this.allCountries = countries;
        });
    },
    computed: {
        routeLocale() {
            return getRouteLocale();
        },
        user() {
            return this.$store.state.AUTH.user;
        },
        profile() {
            return this.$store.state.USER.profile;
        },
        formState() {
            return this.$store.state.USER.formState.profile;
        },
        formStateMessage() {
            return this.$store.state.USER.formStateMessage.profile;
        },
        ...mapGetters("ENUMS", {
            allProfessions: "allStacksNames",
            allTechnologies: "allTechnologiesNames"
        }),
        lookingForJobOptions() {
            return {
                [this.$t('global.time.several_weeks')]: dateAdd(Date.now(), {
                    weeks: 3
                }),
                [this.$t('global.time.months_3')]: dateAdd(Date.now(), {
                    months: 3
                }),
                [this.$t('global.time.months_6')]: dateAdd(Date.now(), {
                    months: 6
                }),
                [this.$t('global.time.year_1')]: dateAdd(Date.now(), {
                    months: 12
                }),
                [this.$tc('global.time.count_years', 2)]: dateAdd(Date.now(), {
                    months: 24
                }),
                [this.$t('global.time.later')]: dateAdd(Date.now(), {
                    months: 36
                })
            }
        },
        showUserNameInputs() {
            return !this.profile || (this.profile && (!this.profile.firstName || !this.profile.lastName))
        }
    },
    watch: {
        profile: {
            immediate: true,
            handler() {
                if (!this.profile) return;

                this.firstName = this.profile.firstName || null;
                this.lastName = this.profile.lastName || null;
                this.country = this.profile.country || null;
                this.technologies = this.profile.technologies || [];

            }
        },
        allProfessions: {
            immediate: true,
            handler() {
                if (!this.allProfessions.length) return

                this.profession = this.profile.profession ? this.allProfessions.find(profession => profession.value === this.profile.profession) : this.profession;
            }
        },
        formState: {
            deep: true,
            handler(state) {
                if (state === "SUCCESS") {
                    const redirectTo = Cookies.get("redirect.after_finish-signup");
                    if (redirectTo) {
                        Cookies.remove("redirect.after_finish-signup");
                        this.$router.push({ name: redirectTo, params: { locale: this.routeLocale } });
                    } else {
                        this.$router.push({ name: "Home", params: { locale: this.routeLocale } });
                    }
                }
            }
        }
    },
    methods: {
        formatAvailableFromDate(date) {
            return dateFormat(date, "yyyy-MM-dd");
        },
        validate() {
            const profession = this.profession.value;
            const technologiesLimit = this.profession.minTechLimit || 3;
            const technologies = this.technologies;
            const firstName = this.firstName;

            if (!(firstName && String(firstName).trim().length > 0)) {
                return this.$t('global.form_errors.enter_name');
            }

            if (!(profession && String(profession).trim().length > 0)) {
                return this.$t('global.form_errors.forgot_profession');
            }

            if (!(technologies && Array.isArray(technologies) && technologies.length >= technologiesLimit)) {
                return this.$t('global.form_rules.min_3_technologies');
            }
        },
        submit() {
            const errorMessage = this.validate();

            if (errorMessage) {
                this.errorMessage = errorMessage;
            } else {
                this.$store.dispatch("USER/UPDATE_PROFILE", {
                    profession: this.profession.value || null,
                    technologies: this.technologies || null,
                    firstName: this.firstName || null,
                    lastName: this.lastName || null,
                    country: this.country || null,
                    language: this.language,
                    availableFrom: this.lookingForJobSwitch ? this.formatAvailableFromDate(Date.now()) : this.formatAvailableFromDate(this.availableFrom),
                    status: "available",
                    cookies: Cookies.get("cookie_utms")
                });
            }
        },
        isState(name, state) {
            return this.formState[name] === state;
        }
    }
};
</script>

<style scoped>
.form-error-message p {
    margin-top: 0 !important;
}
</style>
