<template>
    <div v-if="suggestions && suggestions.length">
        <span class="tw-text-xs tw-block tw-mb-0.5">{{ $t('global.suggested') }}:</span>
        <div class="tw-flex tw-flex-wrap tw--mt-1 tw--ml-1">
            <k-chip v-for="tech in suggestions" @click="$emit('click', tech)" class="tw-uppercase tw-m-1" appearance="outline" :key="tech">
                {{ tech }}
            </k-chip>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import KChip from '@web/components/core/k-chip'
export default {
    components: {KChip},
    props: {
        profession: {
            type: String
        },
        excludeTechnologies: {
            type: Array
        },
        limit: {
            type: [String, Number],
            default: 3
        },
        shuffle: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters("ENUMS", {
            allProfessions: "allStacksNames"
        }),
        allTechnologies() {
            return this.$store.state.ENUMS.technologies_all;
        },
        suggestionsByRadarTech() {
            const allTechnologies = this.arrayOf(this.allTechnologies);
            const allProfessions = ["Dev/Ops", "Frontend", "Backend", "Style", "Database"];
            const suggestions = {};

            allProfessions.forEach(profession => {
                suggestions[profession] = allTechnologies
                    .filter(technology => technology.radarInfo && technology.radarInfo.find(rInfo => rInfo.cat === profession))
                    .sort((t1, t2) => {
                        const r1 = t1.radarInfo.find(rInfo => rInfo.cat === profession);
                        const r2 = t2.radarInfo.find(rInfo => rInfo.cat === profession);

                        return r2.modifier - r1.modifier;
                    })
                    .map(technology => technology.name);
            });

            return suggestions;
        },
        suggestionsByStacks() {
            const allTechnologies = this.arrayOf(this.allTechnologies);
            const allProfessions = this.arrayOf(this.allProfessions);
            const suggestions = {};

            allProfessions.forEach(profession => {
                suggestions[profession.value] = allTechnologies
                    .filter(technology => this.arrayOf(technology.stacks).indexOf(profession.value) !== -1)
                    .map(technology => technology.name);
            });

            return suggestions;
        },
        suggestions() {
            const excludeTechnologies = this.arrayOf(this.excludeTechnologies);

            const professionMap = {
                "Frontend Developer": "Frontend",
                "Backend Developer": "Backend",
                "Fullstack Developer": "Backend",
                "DevOps špecialista": "Dev/Ops"
            };

            let suggestionsForProfession = [];

            if (professionMap[this.profession]) {
                suggestionsForProfession = this.suggestionsByRadarTech[professionMap[this.profession]];
            } else {
                suggestionsForProfession = this.suggestionsByStacks[this.profession];
            }

            if (suggestionsForProfession) {
                const output = this.arrayOf(suggestionsForProfession)
                    .filter(suggest => excludeTechnologies.indexOf(suggest) === -1)
                    .slice(0, Number(this.limit));

                if (this.shuffle) {
                    return output.sort(() => 0.5 - Math.random());
                }

                return output;
            }

            return [];
        }
    },
    methods: {
        arrayOf(value) {
            return Array.isArray(value) ? value : [];
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.chip {
    border-radius: 0.375rem !important;
    background-color: white !important;
    border: 1px solid #f3f4f6 !important;
    padding: 0.125rem 0.5rem !important;

    font-size: 0.875rem !important;
    line-height: 1.6 !important;
    white-space: nowrap !important;
    text-transform: uppercase !important;
    font-family: monospace !important;
    transition: all 250ms ease-in-out;

    &:hover {
        background-color: #f1f0ee !important;
    }
}
</style>
